.description {
  display: flex;
  justify-content: space-between;
  padding: 70px 30px;
  font-family: var(--font-base);

  .values__title {
    min-width: 100px;
  }

  @media screen and (max-width: 450px) {
    padding: 110px 20px;
    justify-content: center;
    flex-direction: column;
  }

  h2 {
    @media screen and (max-width: 450px) {
      padding-right: 10px;
      text-align: left;
    }
  }

  .body {
    max-width: 1000px;
    display: flex;
    gap: 50px;
    position: relative;

    p {
      margin: 0;
      font-size: 4.2rem;

      :first-child {
        margin-right: 0;
      }

      span {
        margin-right: 7px;
      }

      .mask {
        position: relative;
        overflow: hidden;
        display: inline-flex;
        :first-child {
          margin-right: 0;
        }
      }
      @media screen and (max-width: 950px) {
        font-size: 3rem;
      }
      @media screen and (max-width: 600px) {
        font-size: 2.4rem;
      }

      @media screen and (max-width: 450px) {
        font-size: 2.1rem;
      }

      @media screen and (max-width: 350px) {
        font-size: 1.6rem;
      }
    }
  }
}
