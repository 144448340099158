.introduction {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99;
  background-color: var(--black-color);
  svg {
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% + 300px);
    path {
      fill: var(--black-color);
    }
  }
  p {
    display: flex;

    color: var(--white-color);
    font-family: var(--font-base);
    font-size: 4rem;
    font-weight: 600;
    align-items: center;
    position: absolute;
    z-index: 1;
    @media screen and (max-width: 450px) {
      font-size: 2.3rem;
      padding: 0px 30px;
    }

    @media screen and (max-width: 350px) {
      font-size: 1.5rem;
      padding: 0px 30px;
    }

    span {
      display: block;
      width: 10px;
      height: 10px;
      background-color: var(--white-color);
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
