.app__weDo {
  flex: 1;
  width: 100%;
}

.weDo-info {
  @media screen and (max-width: 1200px) {
    margin-bottom: 10px;
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 9fr;
  row-gap: 2rem;
  column-gap: 2rem;

  padding: 20px;

  @media screen and (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 3rem;
  }

  @media screen and (max-width: 450px) {
    align-items: flex-start;
    row-gap: 5px;
  }

  @media screen and (max-width: 350px) {
    padding: 2rem 1rem;
  }
}

.weDo__title {
  flex-direction: column;
  flex-grow: 2;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--black-color);
  padding-right: 50px;

  p {
    font-size: 20px;
  }
}

.weDo-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;

  row-gap: 2rem;
  @media screen and (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:hover {
      scale: 1;
    }
  }
  @media screen and (max-width: 450px) {
    row-gap: 5px;
  }
}

.weDo-card {
  width: 520px;
  height: 250px;
  // background-color: var(--secondary-color);
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  align-items: flex-start;
  flex-direction: row;
  padding: 1rem 1rem;

  .b-bold-text {
    font-size: 1.8rem;
    @media screen and (max-width: 450px) {
      font-size: 1.2rem;
    }
  }

  .card__subtitle p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    @media screen and (max-width: 450px) {
      font-size: 0.9rem;
      margin-bottom: 8px;
    }
  }

  .decoration__weDo {
    color: var(--primary-color);
    font-weight: 800;
    font-size: 1.8rem;
    margin-right: 3px;

    @media screen and (max-width: 1200px) {
      font-size: 1rem;
      font-weight: 800;
      margin-right: 0;
      // visibility: hidden;
    }
  }
  @media screen and (max-width: 450px) {
    display: block;
    width: 360px;
    height: auto;
    padding: 0;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;
  }
}

.card__description-weDo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  row-gap: 15px;

  @media screen and (max-width: 450px) {
    row-gap: 3px;
  }

  .card__description-weDo p {
    font-size: 0.7rem;
    @media screen and (max-width: 450px) {
      font-size: 0.3rem;
    }
  }
}
.toggle-button {
  svg {
    width: 25px;
    height: 25px;
    color: var(--primary-color);

    @media screen and (max-width: 350px) {
      width: 15px;
      height: 15px;
    }
  }
}
