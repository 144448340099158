.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;

  padding: 1rem 2rem 0rem;

  h2 {
    color: transparent;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: var(--black-color);
    font-size: 3.5rem;

    @media screen and (max-width: 450px) {
      font-size: 2rem;
    }
  }

  .header-text {
    flex-direction: column;
    position: absolute;
    z-index: 3;
    gap: 50px;
  }
  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 0rem 0rem 0rem;
  }

  .transparent-title span {
    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }
}

.app__header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  margin: 0 2rem;

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0rem;
  }

  @media screen and (max-width: 450px) {
    width: 100%;
    margin: 0 0rem;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  img {
    width: 950px;
    height: 500px;
    object-fit: cover;
    opacity: 0.8;
    // filter: contrast(100%) brightness(100%);
    @media screen and (min-width: 2000px) {
      width: 1100px;
      height: 650px;
    }

    @media screen and (max-width: 1200px) {
      width: 700px;
      height: 700px;
    }

    @media screen and (max-width: 450px) {
      width: 310px;
      height: 520px;
    }
    @media screen and (max-width: 330px) {
      width: 280px;
      height: 420px;
    }
  }
}

.tag-cmp {
  flex-direction: column;
  margin-top: 3rem;

  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.slider {
  position: relative;
  white-space: nowrap;
}

.slider__text {
  position: relative;
  margin: 0px;
  color: transparent;
  font-size: 6rem;
  font-weight: 500;
  padding-right: 50px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--white-color);
}

.slider__text:nth-of-type(2) {
  position: absolute;
  left: 100%;
  top: 0;
}
