.navbar {
  width: 100%;

  display: flex;
  justify-content: space-between;
}

.app__navbar {
  background: transparent;

  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  // border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  right: 1px;
  top: 10px;
  z-index: 99;
  font-family: var(--font-base);
  @media screen and (max-width: 450px) {
  }
}

.logo {
  display: flex;
  justify-content: flex-start;

  .fixed_menu {
    font-weight: 700;
    position: fixed;
    width: 150px;
    mix-blend-mode: difference;
    left: 0;
    z-index: 2;
    padding-left: 8px;
    margin-top: 20px;

    svg {
      // position: relative;
      // filter: invert(100%) hue-rotate(180deg);
      .cls-1 {
        fill: var(--white-color);
        // filter: invert(100%) hue-rotate(360deg);
        // isolation: isolate;
      }

      .cls-2 {
        fill: var(--white-color);
      }
    }
  }
}

// @media screen and (min-width: 900px) {
//   display: none;
// }

//   @media screen and (min-width: 900px) {
//     display: none;
//   }

.header {
  position: relative;
  margin-top: 20px;
  margin-right: 40px;

  .menu {
    width: 480px;
    height: 650px;
    background-color: var(--gray-color);
    border-radius: 25px;
    position: relative;

    @media screen and (max-width: 450px) {
      width: 150px;
      height: 400px;
    }
  }
}

.cls-1 {
  fill: #805ce5;
}

.cls-1,
.cls-2 {
  stroke-width: 0px;
}

.cls-2 {
  fill: #0c0814;
}
