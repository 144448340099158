.app__team {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-info {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__team-badge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 50px;
  height: 600px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    gap: 20px;
  }
}

.team-text {
  display: flex;
  flex-direction: column;
  width: 500px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  .team-title {
    font-size: 3rem;
    font-family: var(--font-base);
    font-weight: 600;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 450px) {
      font-size: 1.5rem;
    }
  }

  .team-subtitle {
    font-size: 1.6rem;
    @media screen and (max-width: 450px) {
      font-size: 0.9rem;
      margin-bottom: 5px;
    }
  }

  .hi {
    color: var(--primary-color);
  }

  p {
    font-size: 20px;

    @media screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
}

.team__description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  @media screen and (max-width: 450px) {
    gap: 3px;
  }

  p {
    text-align: start;
    @media screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
}

.team-img {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 490px;
    height: 580px;
    object-fit: cover;

    @media screen and (max-width: 1200px) {
      width: 400px;
      height: 480px;
    }

    @media screen and (max-width: 450px) {
      width: 250px;
      height: auto;
    }
  }
}
