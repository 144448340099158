.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .body {
    padding-top: 100px; /* Adjust padding as needed */
    width: 100%;
    max-width: 1800px;

    .title {
      padding: 1rem;

      .title__footer {
        font-size: 3.5rem;
        font-weight: 600;
        align-items: flex-start;
        justify-content: flex-start;

        @media screen and (max-width: 450px) {
          justify-content: center;
        }
      }
      .symbol {
        width: 80px; /* Adjust size of symbol as needed */
        height: 80px;
        margin-bottom: -25px; /* Adjust margin as needed */
      }

      .arrow {
        width: 25px; /* Adjust size of arrow as needed */
        height: 6px;
        margin-right: 8px; /* Adjust margin as needed */

        @media screen and (max-width: 450px) {
          width: 15px;
          height: 6px;
        }
      }

      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .contact {
      padding: 1rem;
      border: 0.3px solid var(--primary-color); /* Adjust border width and color */
      display: flex;
      flex-direction: row; /* Adjust flex-direction as needed */
      justify-content: space-between; /* Adjust alignment as needed */
      align-items: center; /* Adjust alignment as needed */

      a {
        font-size: 1.2rem; /* Adjust font size as needed */
        text-decoration: none;
        color: var(--white-color);
        margin-bottom: 0.5rem; /* Adjust margin as needed */
      }
    }

    .policy {
      display: flex;
      flex-direction: row; /* Adjust flex-direction as needed */
      justify-content: space-between; /* Adjust alignment as needed */
      align-items: center; /* Adjust alignment as needed */
      padding: 2rem 1rem; /* Adjust padding as needed */

      a {
        font-size: 1.2rem; /* Adjust font size as needed */
        text-decoration: none;
        color: var(--white-color);
        margin-bottom: 0.5rem; /* Adjust margin as needed */
      }
    }
  }

  @media screen and (max-width: 768px) {
    .body {
      padding-top: 200px; /* Adjust padding as needed */

      .title {
        .symbol {
          width: 60px; /* Adjust size of symbol as needed */
          height: 60px;
          margin-left: 62px;
          /* Adjust margin as needed */
        }

        .arrow {
          img {
            height: 25px;
            margin-right: 6px; /* Adjust margin as needed */
          }
        }
      }

      .contact,
      .policy {
        flex-direction: column; /* Adjust to column layout on smaller screens */
        align-items: center; /* Center align items */
        gap: 30px;

        a {
          font-size: 1rem; /* Adjust font size as needed */
        }
      }
    }
  }
}
