.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 100px 40px 50px 40px;
  height: 100%;
  box-sizing: border-box;

  .body {
    display: flex;
    gap: 10px;
    flex-direction: column;
    .linkContainer {
      perspective: 120px;
      perspective-origin: bottom;
    }
    a {
      font-family: var(--font-base);
      text-decoration: none;
      color: var(--black-color);
      font-size: 46px;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        color: var(--primary-color);
        font-weight: 600;
      }
      @media screen and (max-width: 650px) {
        font-size: 32px;
      }
    }
  }

  @media screen and (max-width: 650px) {
    padding: 50px 40px 50px 40px;
  }
  // .footer {
  //   display: flex;
  //   flex-wrap: wrap;
  //   a {
  //     width: 50%;
  //     margin-top: 5px;
  //     cursor: pointer;
  //     &:hover {
  //       color: var(--primary-color);
  //     }
  //   }
  // }
}
