.cursor {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid white;
  position: fixed;
  z-index: 999;

  @media screen and (max-width: 450px) {
    display: none;
  }
}
