@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

:root {
  --font-base: 'Syne', sans-serif;
  --font-secondary: 'DM Sans', sans-serif;

  --primary-color: #805ce5;
  --secondary-color: #201747;

  --tertiary-color: #e9e9e7;
  --black-color: #000000;
  --white-color: #ffffff;
  --gray-color: #f0f0f0;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-snap-type: y mandatory;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: var(--black-color);
  color: var(--white-color);
}

section {
  height: 100vh;
  width: 100vw;
  scroll-snap-align: center;
  overflow: hidden;
  padding: 70px 30px;

  @media screen and (max-width: 450px) {
    padding: 0px 0px;
  }
}
