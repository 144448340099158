.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.about__title {
  flex-direction: column;
  color: var(--white-color);
  @media screen and (max-width: 1200px) {
    margin-top: 50px;
  }

  @media screen and (max-width: 450px) {
    padding-top: 30px;
  }

  h1 {
    font-size: 3rem;

    @media screen and (max-width: 450px) {
      font-size: 2rem;
      margin-top: 30px;
    }
  }
}

.app__profiles {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8rem;

  .decoration__about {
    color: var(--primary-color);
    font-weight: 800;
  }

  @media screen and (max-width: 1180px) {
    flex-direction: column;
  }
  @media screen and (max-width: 1000px) {
    margin-top: 0.5rem;
  }
}

.app__profile-item {
  width: 340px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem;

  h2 {
    margin-top: 20px;
    font-weight: 600;
    font-size: 1.6rem;

    @media screen and (max-width: 450px) {
      margin-top: 0px;
      font-size: 1.4rem;
    }
  }

  svg {
    margin: 4px;
    width: 25px;
    height: 25px;
    color: var(--primary-color);
    @media screen and (max-width: 450px) {
      margin-top: 1rem;
    }

    @media screen and (max-width: 350px) {
      width: 15px;
      height: 15px;
    }
  }
  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;
  }
  @media screen and (max-width: 600px) {
    margin-top: 1rem;
  }
  @media screen and (max-width: 350px) {
    margin-top: 0.5rem;
  }
}

.card__description {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative; // Change to relative positioning
  top: auto; // Reset the top property
  left: 0;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); // Optional: Add some shadow for better visibility
  padding: 1rem; // Optional: Add some padding
  z-index: 1; // Ensure it appears above

  p {
    font-size: 1.3rem;

    @media screen and (max-width: 450px) {
      font-size: 1.1rem;
    }
  }
}

button {
  border: none;
  background: none;
}
