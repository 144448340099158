.why__container {
  display: flex;
  align-items: flex-start;

  height: 100vh;

  padding: 2rem 1rem;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }

  @media screen and (max-width: 768px) {
    padding: 5rem 1rem;
  }

  @media screen and (max-width: 450px) {
    margin-top: 5px;
    padding: 7rem 1rem;
  }

  .why__title {
    width: 100%;
    display: flex;
    flex-direction: row;

    @media screen and (min-width: 1200px) {
      width: 20%;
      margin-bottom: 0;
    }

    @media screen and (max-width: 450px) {
      margin-bottom: 5px;
    }
  }

  .why__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    @media screen and (min-width: 1200px) {
      width: 75%;
      flex-direction: row;
    }

    span {
      img {
        width: 20px;
        height: 15px;
        margin-right: 10px;

        @media screen and (max-width: 450px) {
          width: 8px;
          height: 8px;
        }
      }
    }

    .why__text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      row-gap: 1.3rem;
      width: 100%;

      h1,
      h2 {
        font-size: 2.8rem;
        text-align: left;

        @media screen and (max-width: 768px) {
          font-size: 2rem;
        }
      }

      .p-text {
        font-size: 1.4rem;

        @media screen and (max-width: 768px) {
          font-size: 1.1rem;
        }
      }
    }
  }
}
