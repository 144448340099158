.button {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 40px;
  cursor: pointer;

  @media screen and (max-width: 780px) {
    width: 70px;
    height: 30px;
    right: -25px;
  }

  .el {
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;

    &:hover {
      .perspectiveText {
        transform: rotateX(90deg);

        p {
          &:nth-of-type(1) {
            transform: translateY(-100%);
            opacity: 0;
          }

          &:nth-of-type(2) {
            opacity: 1;
          }
        }
      }

      @media screen and (max-width: 450px) {
        position: absolute;
      }
    }
  }
}

.perspectiveText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  transition: transform 0.75s cubic-bezier(0.76, 0, 0.24, 1);

  p {
    transition: all 0.75s cubic-bezier(0.76, 0, 0.24, 1);
    pointer-events: none;
    text-transform: uppercase;

    &:nth-of-type(2) {
      position: absolute;
      transform-origin: bottom center;
      transform: rotateX(-90deg) translateY(9px);
      opacity: 0;
    }
  }
}
